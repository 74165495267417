<template>
  <img v-if="icon" :src="icon" style="width: 20px;margin-right: 10px;" />
  <i v-else class="el-icon-document" style="font-size: 30px;padding-top: 10px;margin-right: 10px;" />
</template>

<script>
export default {
  props: {
    fileName: {
      type: String,
      required: true
    }
  },
  data () {
    return {}
  },
  computed: {
    icon () {
      if (this.fileName && this.fileName.indexOf('.') === -1) return null
      const suffix = this.fileName.substring(this.fileName.lastIndexOf('.') + 1)
      if ('doc,.docx,.ppt,.pptx,.xls,.xlsx,.wps,.pdf,.txt,.jpeg,.jpg,.png,.gif'.indexOf(suffix) === -1) return null
      return require('./images/' + suffix + '.png')
    }
  }
}
</script>

<style>

</style>
