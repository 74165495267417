<template>
    <div class="uploadFile h-font-lg" id="uploadFile">
        <div class="uploadFile-title">
            <span>上传资料</span>
        </div>
        <div class="base h-group car-des">
            <div class="h-cell h-cell--center">
                <div class="h-cell__title" style="font-size:20px">{{ plateNoName }}</div>
                <div class="h-cell__value">
                </div>
            </div>
            <div class="h-cell__content">
                <div class="warn">
                <i class="van-icon van-icon-warning" /> {{ riskDesc }}
                </div>
            </div>
        </div>
        <div class="compulsory h-group">
            <div class="h-cell__content">
                <van-row>
                    <van-col span="24" style="margin-bottom: 6px;">
                        <HSelect :config="config.fileCategoryCode" />
                    </van-col>
                    <van-col span="7"  class="h-text-color_gray-6 title-phone">上传资料</van-col>
                    <van-col span="17" class="upload-icon-cla">
                        <van-uploader ref="upload" :after-read="afterRead">
                            <van-icon name="add" color="#57CCC2" size="24px" />
                        </van-uploader>
                    </van-col>
                </van-row>
                <van-row v-for="(item, ind) in uploadFormFiles" :key="ind">
                    <van-col span="10" style="padding-left: 6%;" class="hide-font">
                        <span>{{ item.fileCategoryName }}</span>
                    </van-col>
                    <van-col span="12" style="padding-left: 6%;" class="hide-font">
                        <!-- <HFileIcon :fileName="item.fileName" /> -->
                        <span>{{ item.fileName }}</span>
                    </van-col>
                    <van-col span="2"><van-icon @click="delEditFile(item)" name="delete" color="red" size="18px" /></van-col>
                </van-row>
            </div>
        </div>
        
        <div class="quote-result_bnt">
            <div class="quote-result_bnt--fixed success">
                <van-button type="primary" style="margin-right: 40px;" block round @click="confirm">确定</van-button>
                <van-button type="default" style="border: 1px solid rgb(87, 204, 194); color: rgb(87, 204, 194);" block round @click="back">取消</van-button>
            </div>
        </div>
    </div>
</template>
<script>
import { mapState } from "vuex";
import { request } from "@/api/service";
import dayjs from 'dayjs'
import { Notify, Toast, Dialog } from 'vant'
import HSelect from '@/components/form-items/HSelect'
import HField from '@/components/form-items/HField'
import store from '@/store'
import HFileIcon from './HFileIcon'
import ImagesQuicklyCompress from 'images-quickly-compress'
export default {
  name: 'uploadFile',
  components: { HSelect, HField, HFileIcon },
  provide() {
    return {
      pageContext: this
    };
  },
  data() {
    return {
        resData: {
            basis: {},
            commercial: {},
            compulsory: {}
        },
        paramsData: {
            basis: {},
            commercial: {},
            compulsory: {}
        },
        permBtns: [],
        config: {
            fileCategoryCode: {
                label: "资料类型",
                placeholder: "请选择资料类型",
                disabled: false,
                val: null,
                dictCode: null,
                rules: [
                    {
                        required: true,
                        disabled: false,
                        message: "资料类型必填",
                        trigger: "onChange",
                    }
                ]
            }
        },
        uploadFormFiles: [],
        uploadApi: '/afis-api-manage/invoke/core/auto/saveFileRecordIn',
        uploadListApi: '/afis-auto-web/auto/policy/fileRecord/queryList',
        delApi: '/afis-auto-web/auto/policy/fileRecord/delete',
        subBusinessNo: null
    }
  },
  computed: {
    ...mapState("car", ["quote","quoteRes"]),
    ...mapState("user", ["info"]),
    ...mapState("dict", ["dictMap"]),
    plateNoName(){
      if(!(this.quote&&this.quote.vehicle)){
        return ''
      }
      if(!this.quote.vehicle.plateNo){
        return '新车未上牌'
      }
      return this.quote.vehicle.plateNo
    },
    riskDesc(){
      if(!this.quote){
        return ''
      }
      let compulsoryFlag=false
      let compulsoryNum = 0
      if(this.quote.compulsory){
        compulsoryFlag = true
        compulsoryNum = 1
      }
      let commercialFlag=false
      let commercialNum = 0
      let commercialStr=""
      if(this.quote.commercial && this.quote.commercial.riskList){
        commercialFlag = true
        const commercialList= this.quote.commercial.riskList && this.quote.commercial.riskList.map(item=>item.riskName)
        commercialNum = commercialList && commercialList.length
        commercialStr=`商业险(${commercialList && commercialList.join('、')})`
      }
      return `已选择投保${compulsoryFlag?'交强险':''}${compulsoryFlag&&commercialFlag?'、':''}${commercialFlag?commercialStr:''}，共${compulsoryNum+commercialNum}项`
    },
  },
  created() {
    store.dispatch('dict/loadDict')
  },
  mounted() {
    uploadFile.style.height = (document.documentElement.clientHeight - 60) + 'px'
    this.paramsData = this.quoteRes[this.quote.basis.insuredOrgCode]
    this.config.fileCategoryCode.dictCode = this.paramsData.basis.insuredOrgCode + '_AUTO_MATERIAL'
    this.subBusinessNo = this.paramsData.basis.subBusinessNo || this.quote.basis.subBusinessNo
    this.getList()
  },
  methods: {
    dayjs,
    back () {
        this.$router.go(-1)
    },
    confirm () {
        if (!this.config.fileCategoryCode.val) {
            Notify({ type: 'warning', message: '请选择资料类型' });
            return
        }
        request({
            url: this.uploadApi,
            method: 'post',
            data: {
                subBusinessNo: this.subBusinessNo,
                fileList: this.uploadFormFiles.map((item, index) => {
                    item.seqNo = index
                    return item
                })
            }
        }).then(res => {
            Toast.success('操作成功')
            this.$router.go(-1)
        })
    },
    async afterRead (obj) {
        const { file } = obj
        if (!this.config.fileCategoryCode.val) {
            Notify({ type: 'warning', message: '请选择资料类型' });
            return
        }

        let isLt5M = file.size / 1024 / 1024 < 5
        const suffixMap = {
            'image/tiff': '.tif',
            'application/x-301': '0.301',
            'application/x-906': '0.906',
            'application/x-a11': '.a11',
            'application/postscript': '.ai',
            'audio/aiff': '.aifc',
            'application/x-anv': '.anv',
            'video/x-ms-asf': '.asf',
            'video/avi': '.avi',
            'application/x-bot': '.bot',
            'application/x-c90': '.c90',
            'application/vnd.ms-pki.seccat': '.cat',
            'application/x-cdr': '.cdr',
            'application/x-x509-ca-cert': '.cer',
            'application/x-cgm': '.cgm',
            'java/*': '.class',
            'application/x-cmp': '.cmp',
            'application/x-cot': '.cot',
            'text/css': '.css',
            'application/x-dbf': '.dbf',
            'application/x-dbx': '.dbx',
            'application/x-dcx': '.dcx',
            'application/x-dgn': '.dgn',
            'application/msword': '.dot',
            'application/x-dwf': '.dwf',
            'application/x-dxb': '.dxb',
            'application/vnd.adobe.edn': '.edn',
            'message/rfc822': '.eml',
            'application/x-epi': '.epi',
            'application/x-msdownload': '.exe',
            'application/vnd.fdf': '.fdf',
            'application/x-g4': '.g4',
            'application/x-': '.',
            'application/x-gl2': '.gl2',
            'application/x-hgl': '.hgl',
            'application/x-hpgl': '.hpg',
            'application/mac-binhex40': '.hqx',
            'application/hta': '.hta',
            'text/html': '.htm',
            'text/webviewhtml': '.htt',
            'application/x-icb': '.icb',
            'application/x-ico': '.ico',
            'application/x-iphone': '.iii',
            'application/x-internet-signup': '.ins',
            'video/x-ivf': '.IVF',
            'application/x-jpe': '.jpe',
            'image/jpeg': '.jpeg',
            'image/jpg': '.jpg',
            'application/x-javascript': '.js',
            'audio/x-liquid-file': '.la1',
            'application/x-latex': '.latex',
            'application/x-lbm': '.lbm',
            'video/x-mpeg': '.m1v',
            'audio/mpegurl': '.m3u',
            'application/x-mac': '.mac',
            'application/x-mdb': '.mdb',
            'application/x-mi': '.mi',
            'audio/mid': '.midi',
            'audio/x-musicnet-stream': '.mns',
            'video/x-sgi-movie': '.movie',
            'audio/mp2': '.mp2',
            'audio/mp3': '.mp3',
            'video/x-mpg': '.mpa',
            'video/mpg': '.mpg',
            'application/vnd.ms-project': '.mpp',
            'video/mpeg': '.mpv2',
            'application/x-mmxp': '.mxp',
            'application/x-nrf': '.nrf',
            'text/x-ms-odc': '.odc',
            'application/pkcs10': '.p10',
            'application/x-pkcs7-certificates': '.p7b',
            'application/pkcs7-mime': '.p7m',
            'application/pkcs7-signature': '.p7s',
            'application/x-pci': '.pci',
            'application/x-pcx': '.pcx',
            'application/pdf': '.pdf',
            'application/x-pkcs12': '.pfx',
            'application/x-pic': '.pic',
            'application/x-perl': '.pl',
            'audio/scpls': '.pls',
            'image/png': '.png',
            'application/x-ppm': '.ppm',
            'application/vnd.ms-powerpoint': '.ppt',
            'application/x-pr': '.pr',
            'application/x-prn': '.prn',
            'application/x-ps': '.ps',
            'application/x-ptn': '.ptn',
            'text/vnd.rn-realtext3d': '.r3t',
            'audio/x-pn-realaudio': '.ram',
            'application/rat-file': '.rat',
            'application/vnd.rn-recording': '.rec',
            'application/x-rgb': '.rgb',
            'application/vnd.rn-realsystem-rjt': '.rjt',
            'application/x-rle': '.rle',
            'application/vnd.adobe.rmf': '.rmf',
            'application/vnd.rn-realsystem-rmj': '.rmj',
            'application/vnd.rn-rn_music_package': '.rmp',
            'application/vnd.rn-realmedia-vbr': '.rmvb',
            'application/vnd.rn-realplayer': '.rnx',
            'audio/x-pn-realaudio-plugin': '.rpm',
            'text/vnd.rn-realtext': '.rt',
            'application/x-rtf': '.rtf',
            'application/x-sam': '.sam',
            'application/sdp': '.sdp',
            'application/x-stuffit': '.sit',
            'application/x-sld': '.sld',
            'application/smil': '.smi',
            'application/x-smk': '.smk',
            'application/vnd.ms-pki.certstore': '.sst',
            'application/x-tdf': '.tdf',
            'application/x-tga': '.tga',
            'application/x-tif': '.tif',
            'application/x-bittorrent': '.torrent',
            'text/plain': '.txt',
            'text/iuls': '.uls',
            'application/x-vda': '.vda',
            'application/vnd.visio': '.vsdx',
            'application/x-vst': '.vst',
            'text/xml': '.xml',
            'audio/x-ms-wax': '.wax',
            'application/x-wb2': '.wb2',
            'image/vnd.wap.wbmp': '.wbmp',
            'application/x-wk3': '.wk3',
            'application/x-wkq': '.wkq',
            'video/x-ms-wm': '.wm',
            'application/x-ms-wmd': '.wmd',
            'text/vnd.wap.wml': '.wml',
            'video/x-ms-wmx': '.wmx',
            'application/x-wp6': '.wp6',
            'application/x-wpg': '.wpg',
            'application/x-wq1': '.wq1',
            'application/x-wri': '.wri',
            'application/x-ws': '.ws',
            'text/scriptlet': '.wsc',
            'video/x-ms-wvx': '.wvx',
            'application/vnd.adobe.xfdf': '.xfdf',
            'application/vnd.ms-excel': '.xls',
            'application/x-xlw': '.xlw',
            'application/x-x_b': '.x_b',
            'application/vnd.symbian.install': '.sisx',
            'application/vnd.iphone': '.ipa',
            'application/x-silverlight-app': '.xap	'
        }
        if (!isLt5M) {
        if (suffixMap[file.type] && ['.jpg', '.png', '.jpeg'].includes(suffixMap[file.type])) {
          // 超过5m的图片文件, 启用压缩
          const compressFiles = await new ImagesQuicklyCompress({
            mode: 'pixel', // 根据像素总大小压缩
            num: 1e6, // 压缩后图片的总像素都是100万（相当于1000px * 1000px的图片）
            size: '500kb', // 图片大小超过500kb执行压缩
            imageType: file.type, // jpeg压缩效果十分理想
            quality: 0.8,
            orientation: false
          }).compressor([file])
          isLt5M = compressFiles[0].size / 1024 / 1024 < 5
          file = new window.File(
            [compressFiles[0]], file.name, { type: compressFiles[0].type }
          )
        }
        if (!isLt5M) {
            if (suffixMap[file.type] && ['.jpg', '.png', '.jpeg'].includes(suffixMap[file.type])) {
                // 超过5m的图片文件, 启用压缩
                const compressFiles = await new ImagesQuicklyCompress({
                    mode: 'pixel', // 根据像素总大小压缩
                    num: 1e6, // 压缩后图片的总像素都是100万（相当于1000px * 1000px的图片）
                    size: '500kb', // 图片大小超过500kb执行压缩
                    imageType: file.type, // jpeg压缩效果十分理想
                    quality: 0.8,
                    orientation: false
                }).compressor([file])
                isLt5M = compressFiles[0].size / 1024 / 1024 < 5
                file = new window.File(
                    [compressFiles[0]], file.name, { type: compressFiles[0].type }
                )
                }
                if (!isLt5M) {
                    Toast('请上传5M以下的文件')
                    return
                }
            }
        }


        const formData = new FormData();
        formData.append("file", file);
        formData.append("busiType", 'autoMaterial');
        formData.append("category", 'public');
        request({
            url: '/afis-engine/dfs/storage/upload',
            method: 'post',
            data: formData
        }).then(res => {
            this.uploadFormFiles.push({
                ...file,
                ...res,
                filePath: res.fileUrl,
                fileName: file.name,
                relateId: this.quote.basis.subBusinessNo,
                fileCategoryCode: this.config.fileCategoryCode.val,
                fileFormat: file.name.substring(file.name.lastIndexOf('.'), file.name.length),
                fileCategoryName: this.dictMap[this.quote.basis.insuredOrgCode + '_AUTO_MATERIAL'].filter(item => item.value === this.config.fileCategoryCode.val)[0].label
            })
        })
    },
    getList () {
        request({
            url: this.uploadListApi,
            method: 'post',
            data: {
                subBusinessNo: this.subBusinessNo
            }
        }).then(res => {
            this.uploadFormFiles = res
        })
    },
    delEditFile (data) {
      Dialog.confirm({
        title: '提示',
        message: '确定要删除吗',
      })
      .then(() => {
        if (data.id) {
            request({
                url: this.delApi,
                method: 'post',
                data: { id: data.id }
            }).then(() => {
                this.uploadFormFiles = this.uploadFormFiles.filter(item => item.id !== data.id)
                Toast.success('删除成功')
            })
        } else if (data.fileId) {
            this.uploadFormFiles = this.uploadFormFiles.filter(item => item.fileId !== data.fileId)
            Toast.success('删除成功')
        }
      }).catch(() => {
          // on cancel
      });
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/assets/style/var.scss";
@import "@/assets/style/css/cell.scss";
.uploadFile {
    background: url('./image/bg-title.png') no-repeat fixed;
    background-size: 100% 100%;
    padding: 0 8px;
    .uploadFile-title {
        font-size: 14px;
        color: #fff;
        font-weight: bold;
        padding: 14px 0 20px 0px;
        .van-icon {
            font-weight: bold;
            margin-left: 6px;
            position: relative;
            top: 2px;
        }
    }
    .h-cell__content{
        padding: 6px 8px;
        font-size: $font-size-sm;
        ::v-deep .van-row {
            padding:9px 0;
        }
    }
    .h-cell__title{
        font-weight: $font-weight-bold-lg;
        font-size: $font-size-md;
        span.icon {
            border-radius: $border-radius-sm;
            color: $white;
            padding: 3px;
            margin-right:6px;
        }
    }
    .h-cell{
        padding: 10px 8px;
    }
    .h-cell__value {
        color: #0066FF;
        font-size: 12px;
    }
    .base{
        .warn{
            text-indent: -4px;
            padding-left: 12px;
            font-size: $font-size-xs;
            color: $primary-color;
        }
    }
    .h-group{
        margin-bottom: 10px;
        padding: 8px;
        border-radius: $border-radius-lg;
    }
    .car-des {
        .refresh-des {
            margin-right: 4px;
        }
        .van-icon {
            font-size: 14px;
        }
    }
    .insured-msg {
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        line-clamp: 3;
        -webkit-box-orient: vertical;
    }
    .quote-result_bnt {
        height: 60px;
        .success {
            text-align: center;
            button {
                width: 40%;
            }
        }
    }
    .quote-result_bnt--fixed {
        position: fixed;
        bottom: 0;
        right: 0;
        left: 0;
        z-index: 99;
        background-color: #fff;
        padding: 10px;
        border-top: #f7f8fa solid 2px;
        button {
            width: 100px;
            display: inline-block;
        }
    }
    .van-cell {
        padding-bottom: 0;
        padding-top: 0;
    }
    .title-phone {
        font-size: 14px;
        padding-left: 5%;
        color: #646566;
    }
    .title-des {
        text-align: right;
        padding-right: 43%;
        columns: #646566;
    }
    .upload-cla {
        ::v-deep input, ::v-deep .van-cell__right-icon {
            display: none;
        }
        ::v-deep .van-field__right-icon {
            padding: 0;
        }
    }
    .upload-icon-cla {
        padding-left: 5%;
    }
    .hide-font {
        overflow: hidden;
        text-overflow:ellipsis;
        white-space: nowrap;
    }
}
</style>
